/* Importar la fuente de Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');

body {
  cursor: url('../public/images/cursor.png'), auto;
  background-color: black;
}


::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
background: rgb(0,0,0);
background: linear-gradient(97deg, rgba(0,0,0,1) 0%, rgba(193,55,55,1) 25%, rgba(239,68,68,0.6564399441417192) 31%, rgba(12,178,89,0.6676444259344363) 65%, rgba(0,0,0,1) 90%);
border-radius: 5px;

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: pink; 
  border-radius: 5px;
}

a, button {
  cursor: url('../public/images/pointer.png'), auto;
}

h1, p {
  cursor: url('../public/images/text.png'), auto;
}

.font-unbounded {
  font-family: 'Unbounded', sans-serif;
}

.font-libre-franklin {
  font-family: "Libre Franklin", sans-serif;
}

.mt-10{
  margin-top: 5rem;
}

.mt-8{
  margin-top: 4rem;
}

.mt-6{
  margin-top: 3rem;
}

.mt-5{
  margin-top: 2.5rem;
}


.mb-10{
  margin-bottom: 5rem;
}

.mb-8{
  margin-bottom: 4rem;
}

.mb-5{
  margin-bottom: 2.5rem;
}

.mb-6{
  margin-bottom: 3rem;
}


.m-5{
  margin: 2.5rem;
}
.m-10{
  margin: 5rem;
}


.border-2{
  border-width: 2px;
}


.acordeon-radius{
  border-radius: 30px;
}

.text-cream{
  color:#e9eedc;
}

.bg-cream{
  background-color:#e9eedc;
}

.bg-dark-senal{
  background-color: #262626;
}

.bgTRed {
  background-image: url('../public/images/bgtred.png');
  background-size: cover; /* Ajusta el tamaño de la imagen */
  background-color: #E01C24;
}

.bgTGreen {
  background-image: url('../public/images/bgtgreen.png');
  background-size: cover; /* Ajusta el tamaño de la imagen */
  background-color: #019E5B;
}

.bgTPink {
  background-image: url('../public/images/bgtpink.png');
  background-size: cover; /* Ajusta el tamaño de la imagen */
  background-color: #F676A6;
}

.mt-44{
  margin-top: 11rem;

}

.mt-56{
  margin-top: 14rem;

}

.text-h1{
  font-size: 4.75rem;
  line-height: 1;
}


.text-p {
  font-size: 1.15rem;
  line-height: 1.4rem;
}

.text-pink-400 {
  color:#f472b6;
}

.bg_t2_movil{ 
background-image: url('/public/images/foto-pagina-2.png');
background-repeat: no-repeat;
background-size: contain;
background-position: right top;
}

.bg_t2_movil_back{ 
  background-image: url('/public/images/lb2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
  background-color: rgb(26, 26, 25);
}

@keyframes circle-out-top-left {
  from {
    clip-path: circle(125%);
  }
  to {
    clip-path: circle(0% at top left);
  }
}

[transition-style="out:circle:top-left"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) circle-out-top-left both;
}


